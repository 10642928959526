/* custom css for react-tags */
.optionListContainer {
  background: #0b1437 !important;
  color: #fff !important;
}

.searchWrapper {
  font-size: 13px !important;
}

.benifitSearchWrapper > .searchWrapper {
  /* height: 100px !important; */
  padding: 10px !important;
  width: 100% !important;
}

.chip {
  background: none !important;
  color: none !important;
  border-radius: 5px !important;
  font-weight: bold;
}

.react-tagsinput {
  background: none !important;
  overflow-y: auto;
  border-radius: 10px;
  width: 100% !important;
  font-size: 12px;
}
.react-tagsinput-input {
  width: 50% !important;
}

/* button.rbc-active {
  color: #000 !important;
}

.react-tagsinput:focus-visible {
  outline-color: #000;
}

.react-tagsinput-tag {
  background: #3780c2;
  border: none;
  color: #fff;
} */

.ql-editor {
  height: 40vh;
}

/* .react-tagsinput--focused {
  border-color: #d7d7d7;
} */

.react-tagsinput--focused {
  border-color: #7551ff !important;
}

.react-tagsinput-tag {
  background-color: #7551ff !important;
  border: 1px solid #3311db !important;
  color: #fff !important;
  font-size: 14px !important;
  padding: auto;
}

/* Calender Custom Css */
.rbc-off-range-bg {
  background: #000 !important;
}

.rbc-event-content {
  height: 20px;
}

button.rbc-button-link.rbc-show-more {
  margin: 0 5px;
  color: #fff;
}

.rbc-today {
  background-color: #183828;
}

.rbc-day-bg .rbc-off-range-bg {
  background-color: #151117;
}

.rbc-time-header-cell {
  height: 100px;
}

.rbc-toolbar {
  font-size: 12px;
  width: 100%;
}

.rbc-month-header {
  height: 40px;
}

.rbc-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a3f54;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
.rbc-event {
  border-radius: 0px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 5px;
}
.rbc-btn-group > button {
  color: #fff;
}
.rbc-event-content {
  white-space: break-spaces !important;
}

/* chip css */
.chip {
  background: #e2e2e2 !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  color: #000 !important;
}

.icon_cancel {
  filter: invert(48%);
}

/* login page animation */
.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ribbon-component {
  background-color: #1b1a21;
  height: 80px;
  overflow: hidden;
  width: 100%;
}

.movable-image {
  background-image: url('https://www.singlestore.com/images/components/ribbon/rainbow.png');
  animation: drift 20s linear infinite;
  background-repeat: repeat-x;
  background-size: 1600px 100%;
  height: 100%;
  width: 8000px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container > input {
  width: 100%;
  background: none;
}
.react-datepicker__input-container > input:focus-visible {
  border: none;
  outline: none;
}
@keyframes drift {
  0% {
    transform: translate(0);
  }

  to {
    transform: translate(-1600px);
  }
}

/* stripe css */

.StripeElement {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  background-color: white;
}

.StripeElement--focus {
  box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.25);
}

.gradient-border {
  border: 1px solid transparent;
  border-image: linear-gradient(to right, #3780c2, #3780c2, #e6474a);
  border-image-slice: 1;
}

.pac-container.pac-logo {
  z-index: 9999;
}

.chakra-select__wrapper,
.css-afru8v {
  margin-top: 10px !important;
}

.ck-rounded-corners .ck.ck-balloon-panel, .ck.ck-balloon-panel.ck-rounded-corners, .tox.tox-tinymce-aux {
  z-index: 999999 !important
}

.ck.ck-content ul, .ck.ck-content ol {
  padding-left: 40px;
}

.ck.ck-editor__editable_inline h2{
  font-size: 1.5em;
  font-weight: bold;
}

.ck.ck-editor__editable_inline h3{
  font-size: 1.17em;
  font-weight: bold;
}

.ck.ck-editor__editable_inline h4{
  font-weight: bold;
}

.ck-editor__editable_inline {
  min-height: 500px;
  border: 0px 1px 1px 1px solid !important;
  border-color: inherit !important;
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.ck-toolbar{
  border-top-right-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
}

.react-tagsinput-input{
  width: 100% !important;
}

.tox-tinymce{
  border: 0 !important;
  width: 100% !important;
}

.tox-tinymce .tox-toolbar__primary{
  background: none !important;
}
